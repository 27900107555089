import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"



const IndexPage = () => (
  <Layout>
    <Seo title="Home" />
    <div className="w-full min-h-full md:w-11/12 lg:w-10/12 xl:w-9/12 mx-auto text-2xl font-['Inter'] mb-20">
      <div className="grid grid-cols-12 gap-0 ">
        <div className="col-span-12 md:col-span-6 mt-20 md:mt-48 flex justify-start items-center p-4">
          <div>
            <div className="w-10/12 md:w-12/12 text-4xl md:text-4xl lg:text-6xl font-medium text-[#08408F] tracking-tight">
            Digital Product 
            Development
            & Remote Engineers
            For Your Big Ideas
            </div>
            <div className=" text-lg text-left md:text-left mt-16 text-slate-700 font-normal tracking-wider">
            You have solidified your idea. Now we can help you bring your product to market faster, to let you grab your bite.
            </div>
          </div>
        </div>
        <div className="col-span-12 md:col-span-6 mt-10 md:mt-28 p-2 flex justify-end items-center">
          <StaticImage src="../images/home-scene.svg" alt="Team at work" width={700}/>
        </div>

        <div className="col-span-12 bg-[radial-gradient(145.05%_100%_at_50%_0%,#dbeafe_0%,#79b1fb_57.38%,#79b1fb_48.86%)] mt-20 skew-y-1">
          <div className="h-6"></div>
        </div>
        <div className="col-span-12 bg-blue-50 -translate-y-4 ">
          <div className="h-2"></div>
        </div>
    
      <div className="col-span-12 md:col-span-6 flex justify-start mt-28 p-4 md:p-8 h-auto">
        <div className="my-10">
        <StaticImage src="../images/home-stage.svg" alt="Stage of product" width={500}/>
        </div>
      </div>
      <div className="col-span-12 md:col-span-6 mt:0 md:mt-28 flex justify-end items-center ">
        <div className="w-10/12 md:w-12/12 mx-auto mt-8 md:mt-10 mb-20 md:mb-28">
          <div className=" text-3xl md:text-4xl text-center md:text-left font-medium text-[#08408F] tracking-tight">
          Bring us in at any stage
          of your product.    
          </div>
          <div className=" text-base text-left mt-8 md:mt-6 text-zinc-900 font-normal tracking-wide leading-8">
          Wether you need us to build your product from scratch, you need extra engineers to work on your product to get there faster or need help to keep your product up and running, Websbite can help you at any stage of your product.
        </div>
      </div>
      </div>


        <div className="col-span-12 bg-[radial-gradient(145.05%_100%_at_50%_0%,#dbeafe_0%,#79b1fb_57.38%,#498FF1_48.86%)] mt-20 skew-y-1">
          <div className="h-6"></div>
        </div>
        <div className="col-span-12 bg-blue-100 -translate-y-4 ">
          <div className="h-2"></div>
        </div>

      <div className="col-span-12 md:col-span-6 mt-12 md:mt-28 flex justify-start items-start">
        <div className="w-10/12 md:w-auto mx-auto">
          <div className=" text-3xl md:text-4xl text-center md:text-left font-medium text-[#08408F] tracking-tight">
              Let’s start with a conversation.
          </div>
          <p className=" text-base text-left mt-8 md:mt-6 text-zinc-900 font-normal tracking-wide leading-8">
          You have been making descisions and want to deliver products to the market faster. Every decision making process is overwhelming and leads to more questions. Be it product or adding more engineers. 
          </p>
          <p className=" text-base text-left mt-8 md:mt-6 text-zinc-900 font-normal tracking-wide leading-8">
          We understand the challenges when dealing with product and engineering and we have come across many questions ourselves in the past and found solutions while working on various projects. 
          </p>
        </div>
      </div>
      <div className="col-span-12 md:col-span-6 flex justify-center mt-12 md:mt-28 p-5 mb-10">
        <div>
          <div className="flex justify-end">
          <StaticImage src="../images/home-start-converse.svg" alt="Saying hello" width={400}/>
          </div>
          <p className=" text-base text-left mt-8 md:mt-6 text-zinc-900 font-normal tracking-normal leading-8">
          With that understanding as the foundation, we try to answer first as many questions you may have so that you feel confident about taking the next step. Any question big or small is important and we are ready to help you answer.
          </p>
        </div> 
      </div>

      </div>
    </div>
  </Layout>
)


export default IndexPage
